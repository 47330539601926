import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Loader from '../../core/ErrorBoundary/Loader';
import Error from '../../core/ErrorBoundary/Error';
import NoData from '../../core/ErrorBoundary/NoData';
import { INSIGHT_SEARCH } from '../../../constants';
import { dataFetcher } from '../../../dataFetcher';
import moment from 'moment';
import { mediaApi } from '@sitecore-jss/sitecore-jss';
import './InsightsSearchResults.scss';
import InsightsSearchPagination from './InsightsSearchPagination';
//import InsightsSearchFilter from './InsightsSearchFilter';
import { DATE_FORMATS } from '../../../utils/enums';
import { parseFilterData } from '../../../utils/helperUtils';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import InsightsSearchFilter from './InsightsSearchFilter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SortIcon from '../globals/svgicons/SortIcon';

const InsightsSearchResults = ({ fields, t, SocialCardInsights }) => {
  const { itemPerPage } = fields;
  const [sortByNewest, setSortByNewest] = useState(true);
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [mobileLoader, setMobileLoader] = useState(false);
  const topRef = useRef(null);

  const dateFormat = DATE_FORMATS.DEFAULT_NEW_US_SMALL;
  const filter = typeof window !== 'undefined' ? parseFilterData() : undefined;
  const filterValues = filter || {};
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const filters = urlParams.get('filters');
  const page = urlParams.get('page');
  const currentPage = page === null ? 1 : parseInt(page);
  const history = useHistory();
  const sf = filterValues?.sf;
  const loadData = (currentPage, sortByNewest) => {
    const postData = {
      sf: sf,
      filter: {
        ...filterValues,
        offset: currentPage - 1,
        totalCount: result?.data?.totalCount,
        limit: itemPerPage.value,
        searchkeyword: null,
        haslocation: false,
        language: 'en',
        sortByNewest,
      },
    };
    !mobileLoader && setLoading(true);
    //setLoading(true);
    dataFetcher(INSIGHT_SEARCH.POST_URL, 'POST', postData).then(
      (response) => {
        setLoading(false);
        setResult(response);
      },
      (error) => {
        setLoading(false);
        setError(true);
        setResult(<Error errorMsg={t('error-message') || error?.message} />);
      }
    );
  };
  const handlePageChange = (selectedPage) => {
    if (selectedPage !== currentPage) {
      setPageParams(selectedPage);
      // if (!loading && topRef.current) {
      //   topRef.current.scrollIntoView({ behavior: 'smooth' });
      // }
    }
  };

  const setPageParams = (page) => {
    urlParams.set('page', page);
    history.push({
      pathname: location.pathname,
      search: String(decodeURIComponent(urlParams)),
    });
  };
  useEffect(() => {
    loadData(currentPage, sortByNewest);
    urlParams.set('page', 1);
  }, [filters, page, location.search, sortByNewest]);

  const handleSortToggle = () => {
    setSortByNewest(!sortByNewest);
  };

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser && window?.location?.hash === '#results' && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  const checkLoaderMobile = (data) => {
    setMobileLoader(data);
  };

  return (
    <>
      {loading && <Loader />}
      {error && <Error errorMsg={t('error-message')} />}
      {!loading && (
        <section className="insights-search-results">
          <div className="container-xl">
            <div className="row">
              <div className="insightFilter col-lg-4 col-md-12">
                <InsightsSearchFilter
                  InsightsSearchFilters={result?.data?.filters}
                  filteredData={result?.data?.selectedFilters}
                  pageSeleted={currentPage}
                  checkLoaderMobile={checkLoaderMobile}
                />
                <div className="desktop-only-social-card">{SocialCardInsights}</div>
              </div>

              <div className="col-lg-8 col-md-12 insights-search-results-container">
                {/* section for sorting and results count */}
                <div className="filter-container" ref={topRef}>
                  <div className="results-count">
                    <span className="results-title"> Insights: </span>{' '}
                    {result?.data?.totalCount} results
                    {/* {result?.data?.insightItems?.length} results */}
                  </div>

                  <div className="sorting-section">
                    <span className="sorting-title">
                      {' '}
                      {fields?.sortingText?.value}:{' '}
                    </span>
                    <div
                      className="sorting-filter"
                      onClick={handleSortToggle}
                      aria-hidden="true"
                    >
                      <span
                        className={`sort-search-icon ${
                          sortByNewest ? 'rotate-0' : 'rotate-180'
                        }`}
                      >
                        <SortIcon />
                      </span>
                      <span className="sort-text">
                        {sortByNewest ? 'Newest' : 'Oldest'}{' '}
                      </span>{' '}
                    </div>
                  </div>
                </div>

                {result?.data?.insightItems?.length === 0 ||
                  (result?.data?.insightItems === null && (
                    <NoData message={t('No results')} />
                  ))}

                {/* section for search results */}
                <div className="cards-container">
                  {!loading &&
                    result?.data?.insightItems?.map((item, index) => (
                      <div key={index} className="card-wrapper">
                        <div className="card-container">
                          <div className="card-image">
                            <img
                              src={mediaApi.updateImageUrl(item?.imageMobile)}
                              alt={item?.topics}
                              //className="desktop-only"
                            />
                            {/* <img
                          src={mediaApi.updateImageUrl(item?.bannerMobileImage)}
                          alt={'item?.bannerMobileImageAlt'}
                          className="mobile-only"
                        /> */}
                          </div>
                          <div className="card-body">
                            <div className="card-content">
                              <div className="publication-details">
                                <span className="icon-container">
                                  <img
                                    //src="https://uatmanpowerglobal.manpowergroup.com/-/media/project/manpowergroup/mpg-marketing/insights/topics/text.jpg?rev=abf388e1fd9042eab2fb57388f330a0e&extension=webp%2cavif"
                                    src={mediaApi.updateImageUrl(
                                      item?.topicsIconDesktop
                                    )}
                                    alt={item?.topics}
                                    className="icon"
                                  />
                                  {/* <img
                                    src={mediaApi.updateImageUrl(item?.topics?.topicsIconMobile)}
                                    alt={item?.topics?.topicsIconMobileAlt}
                                    className="icon mobile-only"
                                  /> */}
                                </span>
                                <span className="topic publication-details-text">
                                  {item?.topics} &#8226;{' '}
                                </span>
                                <span className="date publication-details-text">
                                  {`${moment(item?.publicationDate).format(
                                    dateFormat
                                  )}`}{' '}
                                  {/* &#8226; */}
                                </span>
                              </div>
                            </div>
                            <div className="story-text">
                              <div className="story-title">{item?.title}</div>
                            </div>
                            <div className="cta-container">
                              <a href={item?.pageURL} className="cta-link">
                                {item?.ctaText}
                              </a>
                              <span className="icon-container">
                                <img
                                  src={item?.ctaIcon}
                                  alt="ctaIcon"
                                  className="cta-icon"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {result?.data?.insightItems?.length > 0 && (
        <InsightsSearchPagination
          handlePageChange={handlePageChange}
          totalRecords={result?.data?.totalCount}
          pageLimit={parseInt(itemPerPage.value)}
          selectedPage={currentPage}
        />
      )}

      <div className="mobile-only-social-card">{SocialCardInsights}</div>
    </>
  );
};

InsightsSearchResults.defaultProps = {
  fields: {
    sortingText: {
      value: '',
    },
    sortItems: [
      {
        id: '',
        url: '',
        name: '',
        displayName: '',
        fields: {
          sortOptionIcon: {
            value: {
              src: '',
              alt: '',
            },
          },
          sortOptionText: {
            value: '',
          },
        },
      },
      {
        id: '',
        url: '',
        name: '',
        displayName: '',
        fields: {
          sortOptionIcon: {
            value: {
              src: '',
              alt: '',
            },
          },
          sortOptionText: {
            value: '',
          },
        },
      },
    ],
    resultText: {
      value: 'Insights: {count} results',
    },
    itemPerPage: {
      value: '',
    },
  },
  t: () => {},
};

InsightsSearchResults.propTypes = {
  fields: PropTypes.shape({
    sortingText: PropTypes.shape({
      value: PropTypes.string,
    }),
    sortItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
        name: PropTypes.string,
        displayName: PropTypes.string,
        fields: PropTypes.shape({
          sortOptionIcon: PropTypes.shape({
            value: PropTypes.shape({
              src: PropTypes.string,
              alt: PropTypes.string,
            }),
          }),
          sortOptionText: PropTypes.shape({
            value: PropTypes.string,
          }),
        }),
      })
    ),
    resultText: PropTypes.shape({
      value: PropTypes.string,
    }),
    itemPerPage: PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  }),
  t: PropTypes.func,
};

export default withTranslation()(InsightsSearchResults);
