import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import PropTypes from 'prop-types';
import { DATE_FORMATS } from '../../../utils/enums';
import moment from 'moment';
import './PageBannerVideoInsights.scss';
import iconPlay from '../../../assets/images/Insights/playIcon.png';
import { mediaApi } from '@sitecore-jss/sitecore-jss';

const PageBannerVideoInsights = ({ fields }) => {
  const [isPlaying, setPlaying] = useState(true);
  const playerRef = useRef(null);
  const { data } = fields;
  const {
    publicationDate,
    topic,
    thumbnailImage,
    mediaSource,
    // videoInternal,
    // thumbnailImageAlt,
    // autoPlay,
  } = data;
  const handlePlay = () => {
    setPlaying(!isPlaying);
  };

  const nullishDate = '0001-01-01T00:00:00Z';
  const isValidDate = nullishDate === publicationDate?.value;
  const dateFormat = DATE_FORMATS.DEFAULT_NEW_US_SMALL;
  return (
    <div className="container-xl">
      <div className="video-player-content">
        <h1 className="video-player-title-insights">{data?.title}</h1>
        <p className="video-player-topics-insights">
          <img src={topic?.topicsIconDesktop} alt="icon" className="topics-icon" />
          <span> {topic?.topicsTitle} </span>
          <span>
            &#8226; {!isValidDate && `${moment(publicationDate).format(dateFormat)}`}{' '}
            {/* &#8226; */}
          </span>
        </p>
      </div>
      <div className="video-player-container">
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          controls={true}
          width="100%"
          height="100%"
          url={
            mediaSource?.mediaType === 'external'
              ? mediaSource?.mediaUrl
              : mediaApi.updateImageUrl(mediaSource?.mediaUrl)
          }
          playIcon={<img src={iconPlay} alt="icon" className="playIconThumbnail" />}
          light={mediaApi.updateImageUrl(thumbnailImage)}
          playing={true}
          onPlay={handlePlay}
          onPause={handlePlay}
          config={{
            youtube: {
              playerVars: { showinfo: 0 },
              controls: false,
            },
            file: {
              attributes: { preload: 'auto', controlsList: 'nodownload' },
              forceVideo: true,
            },
          }}
          // light={thumbnailImage}
        />
      </div>
    </div>
  );
};

PageBannerVideoInsights.defaultProps = {
  fields: {
    data: {
      title: '',
      publicationDate: '',
      topic: {
        topicsTitle: '',
        topicsIconDesktop: '',
        topicsIconDesktopAlt: '',
        topicsIconMobile: '',
        topicsIconMobileAlt: '',
      },
      thumbnailImage: '',
      thumbnailImageAlt: '',
      autoPlay: '',
      mediaSource: {
        mediaUrl: '',
        mediaType: '',
      },
      videoInternal: '',
    },
  },
};

PageBannerVideoInsights.propTypes = {
  fields: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      publicationDate: PropTypes.string,
      topic: PropTypes.shape({
        topicsTitle: PropTypes.string,
        topicsIconDesktop: PropTypes.string,
        topicsIconDesktopAlt: PropTypes.string,
        topicsIconMobile: PropTypes.string,
        topicsIconMobileAlt: PropTypes.string,
      }),
      thumbnailImage: PropTypes.string,
      thumbnailImageAlt: PropTypes.string,
      autoPlay: PropTypes.string,
      mediaSource: PropTypes.shape({
        mediaUrl: PropTypes.string,
        mediaType: PropTypes.string,
      }),
      videoInternal: PropTypes.string,
    }),
  }),
};

export default PageBannerVideoInsights;
