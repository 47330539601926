import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss';
import './RelatedStories.scss';
import { Link } from 'react-router-dom';

const RelatedStories = ({ fields }) => {
  const {
    data: { title, relatedStories, ctaText, ctaLink },
  } = fields;

  return (
    <div className="related-stories">
      <h2>{title}</h2>
      <ul>
        {relatedStories?.map((item, index) => (
          <Link to={item?.pageUrl}>
            <li key={index} className="related-story-item">
              <div className="related-story-content">
                <img
                  src={mediaApi.updateImageUrl(item?.bannerMobileImage)}
                  alt={item?.bannerMobileAlt}
                  className="related-story-image"
                />
                <div className="related-story-text">
                  <h3>{item?.title}</h3>
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
      <a href={ctaLink} className="view-more">
        {ctaText}
      </a>
    </div>
  );
};

RelatedStories.defaultProps = {
  fields: {
    data: {
      title: '',
      relatedStories: [
        {
          bannerMobileImage: '',
          bannerDesktopImage: '',
          bannerMobileAlt: '',
          bannerDesktopAlt: '',
          title: '',
          pageUrl: '',
        },
      ],
      ctaText: '',
      ctaLink: '',
      ctaIcon: '',
      ctaIconAlt: '',
      ctaIconPosition: '',
      showCTAIcon: '',
      showUnderline: '',
      ctaBackgroundColor: {
        buttonRadius: null,
        colorCode: null,
        fontColor: null,
        style: null,
      },
    },
  },
};

RelatedStories.propTypes = {
  fields: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      relatedStories: PropTypes.arrayOf(
        PropTypes.shape({
          bannerMobileImage: PropTypes.string,
          bannerDesktopImage: PropTypes.string,
          bannerMobileAlt: PropTypes.string,
          bannerDesktopAlt: PropTypes.string,
          title: PropTypes.string,
          pageUrl: PropTypes.string,
        })
      ),
      ctaText: PropTypes.string,
      ctaLink: PropTypes.string,
      ctaIcon: PropTypes.string,
      ctaIconAlt: PropTypes.string,
      ctaIconPosition: PropTypes.string,
      showCTAIcon: PropTypes.string,
      showUnderline: PropTypes.string,
      ctaBackgroundColor: PropTypes.shape({
        buttonRadius: PropTypes.string,
        colorCode: PropTypes.string,
        fontColor: PropTypes.string,
        style: PropTypes.string,
      }),
    }),
  }),
};

export default RelatedStories;
