import React from 'react';
import PropTypes from 'prop-types';
import './HomeFeaturedInsights.scss';
import { DATE_FORMATS } from '../../../utils/enums';
import moment from 'moment';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { mediaApi } from '@sitecore-jss/sitecore-jss';

const HomeFeaturedInsights = ({ fields }) => {
  const { data } = fields;
  const { title, featuredCards } = data;

  const dateFormat = DATE_FORMATS.DEFAULT_NEW_US_SMALL;

  const chunkSize = 1; // Number of cards per chunk for mobile view
  const chunks = [];
  for (let i = 0; i < featuredCards.length; i += chunkSize) {
    chunks.push(featuredCards.slice(i, i + chunkSize));
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <div className="home-featured-insights">
      <div className="featured-title">{title}</div>
      <div className="desktop-view">
        {featuredCards.map((card, index) => (
          <div key={index} className="featured-card">
            <p className="home-featured-topics-insights">
              <img
                src={card?.topicsIconDesktop}
                alt="icon"
                className="topics-icon"
              />
              <span> {card?.topic} </span>
              <span>
                &#8226; {`${moment(card?.publishedDate).format(dateFormat)}`}
                {/* &#8226; */}
              </span>
            </p>
            <h3 className="card-title">{card?.title}</h3>
            <div className="home-featured-cta-container">
              <a href={card?.ctaUrl} className="card-cta">
                {card?.ctaText}
              </a>
              <span className="icon-container">
                <img src={card?.ctaIcon} alt="ctaIcon" className="cta-icon" />
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="mobile-view">
        <Slider {...settings}>
          {featuredCards.map((card, idx) => (
            <div key={idx} className="card-cover" style={{ width: 250 }}>
              <a href={card?.ctaUrl}>
                <div className="card-container">
                  {/* <div className="card-image">
                  <img
                    src="https://devmanpowerglobal.manpowergroup.com/-/jssmedia/project/manpowergroup/mpg-marketing/insights/banner/report_horizontal.png"
                    // src={mediaApi.updateImageUrl(card?.bannerMobileImage)}
                    alt={card?.bannerMobileImageAlt}
                  />
                </div> */}
                  <div className="card-body">
                    <div className="card-content">
                      <div className="publication-details">
                        <span className="icon-container">
                          <img
                            src={card?.topicsIconDesktop}
                            alt="icon"
                            className="icon"
                          />
                        </span>
                        <span className="topic publication-details-text">
                          {card?.topic}
                        </span>
                        <span className="date publication-details-text">
                          &#8226;{' '}
                          {`${moment(card?.publishedDate).format(dateFormat)}`}{' '}
                          {/* &#8226; */}
                        </span>
                      </div>
                    </div>
                    <div className="story-text">
                      <div className="story-title">{card?.title}</div>
                    </div>
                    <div className="cta-container">
                      <div className="cta-link">{card?.ctaText} </div>
                      <span className="icon-container">
                        <img
                          src={card?.ctaIcon}
                          alt="ctaIcon"
                          className="cta-icon"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

HomeFeaturedInsights.defaultProps = {
  fields: {
    data: {
      title: '',
      limit: '',
      featuredCards: [
        {
          title: '',
          publishedDate: '',
          topic: '',
          topicsIconDesktop: '',
          ctaText: '',
          ctaUrl: '',
          ctaIcon: null,
        },
      ],
    },
  },
};

HomeFeaturedInsights.propTypes = {
  fields: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.string,
      limit: PropTypes.number,
      featuredCards: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          publishedDate: PropTypes.string,
          topic: PropTypes.string,
          topicsIconDesktop: PropTypes.string,
          ctaText: PropTypes.string,
          ctaUrl: PropTypes.string,
          ctaIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
        })
      ),
    }),
  }),
};

export default HomeFeaturedInsights;
