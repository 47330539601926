import React, { useState, useEffect } from 'react';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import { DATE_FORMATS } from '../../../utils/enums';
import { Carousel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './CarouselThreeColumn.scss';
import RightArrowChervon from '../globals/svgicons/RightArrowChervon';
import LeftArrowChervon from '../globals/svgicons/LeftArrowChervon';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselThreeColumn = ({ fields }) => {
  const { data } = fields;
  const {
    cards,
    cardsPerSlideDesktop,
    cardsPerSlideMobile,
    //showSlideNumber,
  } = data;

  const cardsExist = cardsPerSlideDesktop !== '' || cardsPerSlideMobile !== '';

  // Define the number of cards per slide
  const cardsPerSlideDesk = parseInt(cardsPerSlideDesktop);
  const cardsPerSlideMob = parseInt(cardsPerSlideMobile);
  const [index, setIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  const dateFormat = DATE_FORMATS.DEFAULT_NEW_US_SMALL;

  // Split the cardsData into chunks of size CARDS_PER_SLIDE
  const chunkCards = () => {
    const chunks = [];
    if (typeof window !== 'undefined') {
      if (window.innerWidth >= 1200) {
        for (let i = 0; i < cards.length; i += cardsPerSlideDesk) {
          chunks.push(cards.slice(i, i + cardsPerSlideDesk));
        }
      } else if (window.innerWidth >= 768) {
        for (let i = 0; i < cards.length; i += cardsPerSlideDesk) {
          chunks.push(cards.slice(i, i + cardsPerSlideDesk));
        }
      } else {
        for (let i = 0; i < cards.length; i += cardsPerSlideMob) {
          chunks.push(cards.slice(i, i + cardsPerSlideMob));
        }
      }
    }
    return chunks;
  };

  // Use effect hook to update cardsPerSlide on window resize
  useEffect(() => {
    if (typeof window !== 'undefined') {
      chunkCards(); // Initial call
      window.addEventListener('resize', chunkCards); // Update on resize

      return () => {
        window.removeEventListener('resize', chunkCards); // Cleanup event listener
      };
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };

      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handlePrev = () => {
    setIndex(index === 0 ? chunkCards().length - 1 : index - 1); // Navigate to the previous slide
  };

  const handleNext = () => {
    setIndex(index === chunkCards().length - 1 ? 0 : index + 1); // Navigate to the next slide
  };

  const setSlideCount = (index) => {
    setCurrentSlide(index + 1);
  };

  const renderCards = (cards) => {
    return cards.map((item) => (
      <div
        className={`col-md-${12 / cardsPerSlideDesk}  col-sm-${
          12 / cardsPerSlideMob
        }`}
        key={item.id}
      >
        <div className="card-container">
          <div className="card-image">
            <img
              src={mediaApi.updateImageUrl(item?.bannerMobileImage)}
              alt={item?.bannerMobileImageAlt}
              className="desktop-only"
              style={{ height: `${cardsPerSlideDesk === 1 && '360px'} ` }}
            />
            <img
              src={mediaApi.updateImageUrl(item?.bannerMobileImage)}
              alt={item?.bannerMobileImageAlt}
              className="mobile-only"
            />
          </div>
          <div className="card-body">
            <div className="card-content">
              <div className="publication-details">
                <span className="icon-container">
                  <img
                    src={mediaApi.updateImageUrl(item?.topics?.topicsIconDesktop)}
                    alt={item?.topics?.topicsIconDesktopAlt}
                    className="icon desktop-only"
                  ></img>
                  <img
                    src={mediaApi.updateImageUrl(item?.topics?.topicsIconMobile)}
                    alt={item?.topics?.topicsIconMobileAlt}
                    className="icon mobile-only"
                  ></img>
                </span>
                <span className="topic publication-details-text">
                  {item?.topics?.topicsTitle}
                </span>
                <span className="date publication-details-text">
                  &#8226; {`${moment(item?.publishedDate).format(dateFormat)}`}{' '}
                  {/* &#8226; */}
                </span>
              </div>
            </div>
            <div className="story-text">
              <div className="story-title">{item?.title}</div>
            </div>
            <div className="cta-container">
              <a href={item?.pageUrl} className="cta-link">
                {item?.ctaText}{' '}
              </a>
              <span className="icon-container">
                <img src={item?.ctaIcon} alt="ctaIcon" className="cta-icon" />
              </span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const renderMobileSlider = (cards) => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    };

    return (
      <Slider {...settings}>
        {cards.map((item) => (
          <div key={item.id} className="card-cover">
            <a href={item?.pageUrl}>
              <div className="card-container">
                <div className="card-image">
                  <img
                    src={mediaApi.updateImageUrl(item?.bannerMobileImage)}
                    alt={item?.bannerMobileImageAlt}
                  />
                </div>
                <div className="card-body">
                  <div className="card-content">
                    <div className="publication-details">
                      <span className="icon-container">
                        <img
                          src={mediaApi.updateImageUrl(
                            item?.topics?.topicsIconMobile
                          )}
                          alt={item?.topics?.topicsIconMobileAlt}
                          className="icon"
                        ></img>
                      </span>
                      <span className="topic publication-details-text">
                        {item?.topics?.topicsTitle}
                      </span>
                      <span className="date publication-details-text">
                        &#8226; {`${moment(item?.publishedDate).format(dateFormat)}`}{' '}
                        {/* &#8226; */}
                      </span>
                    </div>
                  </div>
                  <div className="story-text">
                    <div className="story-title">{item?.title}</div>
                  </div>
                  <div className="cta-container">
                    <div className="cta-link">{item?.ctaText} </div>
                    <span className="icon-container">
                      <img src={item?.ctaIcon} alt="ctaIcon" className="cta-icon" />
                    </span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    );
  };

  const chunks = chunkCards();

  return (
    <div className="container-xl carousel-three-column-container">
      <div className="story-header-container  ">
        <div className="story-header">{data?.title}</div>
        {chunks?.length > 1 && (
          <div className="navigation-btn">
            <button className="btn-prev" type="button" onClick={handlePrev}>
              <LeftArrowChervon />
            </button>
            {cardsPerSlideDesk === 1 && (
              <span className="slide-count">
                <span>{currentSlide}</span>/<span>{chunks?.length}</span>
              </span>
            )}
            <button className="btn-next" type="button" onClick={handleNext}>
              <RightArrowChervon />
            </button>
          </div>
        )}
      </div>
      <div className="carousel-container my-4">
        {cardsExist ? (
          isMobile ? (
            renderMobileSlider(cards)
          ) : (
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              onSlid={() => setSlideCount(index)}
              indicators={false}
              controls={false}
              interval={null}
            >
              {chunks.map((chunk) => (
                <Carousel.Item key={chunk.id}>
                  <div className="row"> {renderCards(chunk)}</div>
                </Carousel.Item>
              ))}
            </Carousel>
          )
        ) : (
          <div className="no-carousel-data"> {'Story data not available'} </div>
        )}
      </div>
    </div>
  );
};

CarouselThreeColumn.defaultProps = {
  fields: {
    data: {
      cardsPerSlideDesktop: '',
      cardsPerSlideMobile: '',
      //showSlideNumber: true,
      title: '',
      cards: [
        {
          bannerImage: '',
          bannerImageAlt: '',
          bannerMobileImage: '',
          bannerMobileImageAlt: '',
          title: '',
          publishedDate: '',
          topics: {
            topicsTitle: '',
            topicsIconDesktop: '',
            topicsIconDesktopAlt: '',
            topicsIconMobile: '',
            topicsIconMobileAlt: '',
          },
          pageUrl: '',
          ctaText: '',
          ctaIcon: '',
          ctaIconAlt: '',
          ctaIconPosition: '',
          showCTAIcon: '',
          showUnderline: '',
          ctaBackgroundColor: {
            buttonRadius: '',
            colorCode: '',
            fontColor: '',
            style: '',
          },
        },
      ],
    },
  },
};

CarouselThreeColumn.propTypes = {
  fields: PropTypes.shape({
    data: PropTypes.shape({
      cardsPerSlideDesktop: PropTypes.string,
      cardsPerSlideMobile: PropTypes.string,
      showSlideNumber: PropTypes.bool,
      title: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          bannerImage: PropTypes.string,
          bannerImageAlt: PropTypes.string,
          bannerMobileImage: PropTypes.string,
          bannerMobileImageAlt: PropTypes.string,
          title: PropTypes.string,
          publishedDate: PropTypes.string,
          topics: PropTypes.shape({
            topicsTitle: PropTypes.string,
            topicsIconDesktop: PropTypes.string,
            topicsIconDesktopAlt: PropTypes.string,
            topicsIconMobile: PropTypes.string,
            topicsIconMobileAlt: PropTypes.string,
          }),
          pageUrl: PropTypes.string,
          ctaText: PropTypes.string,
          ctaIcon: PropTypes.string,
          ctaIconAlt: PropTypes.string,
          ctaIconPosition: PropTypes.string,
          showCTAIcon: PropTypes.string,
          showUnderline: PropTypes.string,
          ctaBackgroundColor: PropTypes.shape({
            buttonRadius: PropTypes.string,
            colorCode: PropTypes.string,
            fontColor: PropTypes.string,
            style: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export default CarouselThreeColumn;
