import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import './ContentBlock.scss';

const ContentBlock = ({ fields }) => {
  const content = fields?.content;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <div className="content-wrapper content-insights-block">
      <div className="container-xl">
        <div className={`content-insights ${isExpanded ? 'expanded' : ''}`}>
          <RichText field={content} />
        </div>

        {!isExpanded && (
          <div className="read-more-container">
            <button className="read-more-btn" onClick={toggleReadMore}>
              Read More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

ContentBlock.defaultProps = {
  fields: {
    content: { value: '' },
  },
};

ContentBlock.propTypes = {
  fields: PropTypes.shape({
    content: PropTypes.shape({ value: PropTypes.string.isRequired }),
  }),
};

export default ContentBlock;
