/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HubspotForm from 'react-hubspot-form';
import './NewsLetterCalloutbarInsights.scss';

const NewsLetterCalloutbarInsights = (props) => {
  const { fields } = props;
  const formId = fields?.HubspotFormID?.value;
  const portalId = fields?.HubspotPortalID?.value;
  const formTitle = fields?.formTitle?.value;
  const formContent = fields?.formContent?.value;
  const formBackgroundColor = fields?.formBackgroundColor?.fields?.colorCode?.value;
  const formFontColor = fields?.formBackgroundColor?.fields?.fontColor?.value;
  const formDesktopIcon = fields?.formDesktopIcon?.value;
  const formMobileIcon = fields?.formMobileIcon?.value;

  if (formId && portalId) {
    return (
      <section className="container-xl newsletter-calloutbar-insights">
        <div className=" newsletter-calloutbar-insights-container" style={{ background: formBackgroundColor, color: formFontColor }}>
          <div className="row align-items-center row-newsletter-container">
            <div className="col-md-2 newsletter-icon-div">
              <div className="newsletter-icon-container">
                <img
                  src={formDesktopIcon?.src}
                  alt={formDesktopIcon?.alt}
                  className="newsletter-icon d-none d-md-block"
                />
                <img
                  src={formMobileIcon?.src}
                  alt={formMobileIcon?.alt}
                  className="newsletter-icon d-block d-md-none"
                />
              </div>
            </div>
            <div className="col-md-5 newsletter-content-div">
              <div className="newsletter-content">
                <h2 className="newsletter-title">{formTitle}</h2>
                <p className="newsletter-description">{formContent}</p>
              </div>
            </div>
            <div className="col-md-5 newsletter-form-div">
              <div className="hubspotForm">
                <div className="hubspot-form-render">
                  <HubspotForm
                    key={props?.updatedCategories}
                    portalId={portalId}
                    formId={formId}
                    onSubmit={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return <div className="hubspotForm-empty">{"No Hubspot ID available"}</div>;
};

NewsLetterCalloutbarInsights.defaultProps = {
  fields: {
    formTitle: {
      value: '',
    },
    formContent: {
      value: '',
    },
    formBackgroundColor: {
      id: '',
      url: '',
      name: '',
      displayName: '',
      fields: {
        colorCode: {
          value: '',
        },
        fontColor: {
          value: '',
        },
        style: {
          value: '',
        },
      },
    },
    formDesktopIcon: {
      value: {
        src: '',
        alt: '',
      },
    },
    formMobileIcon: {
      value: {
        src: '',
        alt: '',
      },
    },
    HubspotFormID: {
      value: '',
    },
    HubspotPortalID: {
      value: '',
    },
    hubspotTitle: {
      value: '',
    },
  },
};

NewsLetterCalloutbarInsights.propTypes = {
  fields: PropTypes.shape({
    formTitle: PropTypes.shape({
      value: PropTypes.string,
    }),
    formContent: PropTypes.shape({
      value: PropTypes.string,
    }),
    formBackgroundColor: PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      name: PropTypes.string,
      displayName: PropTypes.string,
      fields: PropTypes.shape({
        colorCode: PropTypes.shape({
          value: PropTypes.string,
        }),
        fontColor: PropTypes.shape({
          value: PropTypes.string,
        }),
        style: PropTypes.shape({
          value: PropTypes.string,
        }),
      }),
    }),
    formDesktopIcon: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
    formMobileIcon: PropTypes.shape({
      value: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
    HubspotFormID: PropTypes.shape({
      value: PropTypes.string,
    }),
    HubspotPortalID: PropTypes.shape({
      value: PropTypes.string,
    }),
    hubspotTitle: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
};

export default withTranslation()(NewsLetterCalloutbarInsights);