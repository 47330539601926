import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import ButtonLinkInsights from '../globals/link/ButtonLinkInsights';

const CalloutBarSimple = (props) => {
  const {
    title,
    content,
    componentBackgroundColor,
    backgroundImageDesktop,
    backgroundImageMobile,
    ctaText,
    ctaLink,
    ctaBackgroundColor,
  } = props?.fields;

  const isBrowser = typeof window !== 'undefined';

  const backgroundStyle = {
    componentBackgroundColor: componentBackgroundColor?.fields?.colorCode?.value,
    backgroundImage: isBrowser
      ? `url(${
          window.innerWidth <= 768
            ? backgroundImageMobile?.value?.src
            : backgroundImageDesktop?.value?.src
        })`
      : 'none',
  };

  return (
    <div className="callout-bar-simple">
      <div className="container callout-bar-simple-body">
        <div
          className="row align-items-center callout-bar-simple-content p-4"
          style={{
            ...backgroundStyle,
            backgroundColor: componentBackgroundColor?.fields?.colorCode?.value,
          }}
        >
          <div className="col-lg-8">
            <div>
              <h2 className="card-title">{title?.value}</h2>
              <RichText field={content} />
            </div>
          </div>
          <div className="col-lg-4 text-lg-right callout-bar-simple-button">
            <ButtonLinkInsights
              mouseHoverColor={ctaBackgroundColor?.fields?.mouseHoverColor?.value}
              buttonRadius={ctaBackgroundColor?.fields?.buttonRadius?.value}
              ctaFontColour={ctaBackgroundColor?.fields?.fontColor?.value}
              ctaText={ctaText}
              ctaBackgroundColor={ctaBackgroundColor?.fields?.colorCode?.value}
              ctaUrl={ctaLink?.value?.href}
              externalLink={ctaLink?.value?.linktype === 'external'}
              arrow
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CalloutBarSimple.defaultProps = {
  title: { value: '' },
  content: { value: '' },
  componentBackgroundColor: {
    fields: {
      colorCode: { value: '' },
      fontColor: { value: '' },
      style: { value: '' },
    },
  },
  backgroundImageDesktop: {
    value: {
      src: '',
      alt: '',
    },
  },
  backgroundImageMobile: {
    value: {
      src: '',
      alt: '',
    },
  },
  ctaText: { value: '' },
  ctaLink: {
    value: {
      href: '',
      text: '',
      linktype: '',
    },
  },
  ctaIcon: {
    value: {
      src: '',
      alt: '',
    },
  },
  showCTAIcon: { value: false },
  ctaIconPosition: {
    fields: {
      text: { value: '' },
    },
  },
};

CalloutBarSimple.propTypes = {
  title: PropTypes.shape({ value: PropTypes.string }),
  content: PropTypes.shape({ value: PropTypes.string }),
  ctaText: PropTypes.shape({ value: PropTypes.string }),
  ctaLink: PropTypes.shape({
    value: PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      linktype: PropTypes.string,
    }),
  }),
  backgroundImageDesktop: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  backgroundImageMobile: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  componentBackgroundColor: PropTypes.shape({
    fields: PropTypes.shape({
      colorCode: PropTypes.shape({ value: PropTypes.string }),
      fontColor: PropTypes.shape({ value: PropTypes.string }),
      style: PropTypes.shape({ value: PropTypes.string }),
    }),
  }),
  ctaIcon: PropTypes.shape({
    value: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  }),
  showCTAIcon: PropTypes.shape({ value: PropTypes.bool }),
  ctaIconPosition: PropTypes.shape({
    fields: PropTypes.shape({
      text: PropTypes.shape({ value: PropTypes.string }),
    }),
  }),
};

export default CalloutBarSimple;
