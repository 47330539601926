import React from 'react';
import PropTypes from 'prop-types';
import { mediaApi } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import { DATE_FORMATS } from '../../../../utils/enums';
import './NextStory.scss';

const NextStory = ({ fields }) => {
  const { data } = fields;
  const { item } = data;

  const nullishDate = '0001-01-01T00:00:00Z';
  const isValidDate = nullishDate === item?.publicationDate;
  const dateFormat = DATE_FORMATS.DEFAULT_NEW_US_SMALL;

  return (
    <div className="next-story-container">
      <div className="story-header">{data?.title}</div>
      <div className="story-body col-md-12">
        <div className="story-image col-md-4 col-sm-4">
          <img
            src={mediaApi.updateImageUrl(item?.bannerImageMobile)}
            alt={item?.bannerImageMobileAlt}
            className="image"
          />
        </div>
        <div className="story-text col-md-8 col-sm-8">
          <div className="top-line">
            <div className="publication-details">
              <span className="icon-container">
                <img
                  src={mediaApi.updateImageUrl(item?.topic?.topicsIconDesktop)}
                  alt="icon"
                  className="icon"
                ></img>
              </span>
              <span className="topic publication-details-text">
                {item?.topic?.topicsTitle}
              </span>
              <span className="date publication-details-text">
                {!isValidDate &&
                  `${moment(item?.publicationDate).format(dateFormat)}`}
              </span>
            </div>
            <h5 className="mb-2">{item?.title}</h5>
          </div>
          <div className="cta-container">
            <a href={item?.pageUrl} className="cta-link">
              {item?.ctaText}{' '}
            </a>
            <span className="icon-container">
              <img src={item?.ctaIcon} alt="ctaIcon" className="cta-icon" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

NextStory.defaultProps = {
  fields: {
    data: {
      title: '',
      item: {
        pageUrl: '',
        bannerImageDesktop: '',
        bannerImageDesktopAlt: '',
        bannerImageMobile: '',
        bannerImageMobileAlt: '',
        title: '',
        publicationDate: '',
        ctaText: '',
        ctaIcon: '',
        ctaIconPosition: '',
        showCTAIcon: '',
        showUnderline: '',
        ctaBackgroundColor: null,
        topic: {
          targetItem: {
            topicsTitle: '',
            topicsIconDesktop: '',
          },
        },
      },
    },
  },
};

NextStory.propTypes = {
  fields: PropTypes.shape({
    data: PropTypes.shape({
      title: PropTypes.shape({
        value: PropTypes.string,
      }),
      item: PropTypes.shape({
        pageUrl: PropTypes.shape({
          value: PropTypes.string,
        }),
        bannerImageDesktop: PropTypes.shape({
          value: PropTypes.string,
        }),
        bannerImageDesktopAlt: PropTypes.shape({
          value: PropTypes.string,
        }),
        bannerImageMobile: PropTypes.shape({
          value: PropTypes.string,
        }),
        bannerImageMobileAlt: PropTypes.shape({
          value: PropTypes.string,
        }),
        title: PropTypes.shape({
          value: PropTypes.string,
        }),
        publicationDate: PropTypes.shape({
          value: PropTypes.string,
        }),
        ctaText: PropTypes.shape({
          value: PropTypes.string,
        }),
        ctaIcon: PropTypes.shape({
          value: PropTypes.string,
        }),
        ctaIconPosition: PropTypes.shape({
          value: PropTypes.string,
        }),
        showCTAIcon: PropTypes.shape({
          value: PropTypes.string,
        }),
        showUnderline: PropTypes.shape({
          value: PropTypes.string,
        }),
        ctaBackgroundColor: null,
        topic: PropTypes.shape({
          targetItem: PropTypes.shape({
            topicsTitle: PropTypes.shape({
              value: PropTypes.string,
            }),
            topicsIconDesktop: PropTypes.shape({
              value: PropTypes.string,
            }),
          }),
        }),
      }),
    }),
  }),
};

export default NextStory;
