import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './InsightsBreadcrumbLink.scss';
import { mediaApi } from '@sitecore-jss/sitecore-jss';
import RightChevronIcon from '../globals/svgicons/RightChervonIcon';

/**
 * @description - Breadcrumb Links component.
 * @param {Object} param0 - Input props.
 * @returns {Node} - HTML template for Breadcrumb.
 */
const InsightsBreadcrumbLink = ({ text, url, isLastItem, icon }) => (
  <li
    className={
      isLastItem ? 'insights-breadcrumb-item last-item' : 'insights-breadcrumb-item'
    }
  >
    <Link
      to={url === null ? '#' : url}
      {...(isLastItem ? { 'aria-current': 'page' } : '')}
    >
      {icon ? (
        <div>
          <img
            src={mediaApi.updateImageUrl(icon)}
            alt="home-icon"
            className="home-icon"
          />
        </div>
      ) : (
        text
      )}
    </Link>
    {!isLastItem && <RightChevronIcon className="insights-svg-icon" />}
  </li>
);

InsightsBreadcrumbLink.defaultProps = {
  text: '',
  url: '',
  isLastItem: false,
  icon: '',
};

InsightsBreadcrumbLink.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  isLastItem: PropTypes.bool,
  icon: PropTypes.string,
};

export default InsightsBreadcrumbLink;
